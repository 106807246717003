import { Injectable } from "@angular/core";
import { HttpService } from "@app/core/http";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import { ToastrService } from "ngx-toastr";
import {
  AddEditSecurityPatch,
  EditCms,
  FetchPluginList,
  SecurityAddPluginList,
  SecurityPatchDelete,
  UpdateMilestoneStatus,
  UpdateVirusMalware,
  WebsiteManagementDelete,
  WebsiteManagementList,
  WebsiteManagementView,
} from "../actions/website-management.action";
import { mergeMap, tap } from "rxjs";
import { patch, removeItem } from "@ngxs/store/operators";

interface IWebsiteManagementStateModel {
  websiteList: IWebsiteManagementList[];
  websiteListCount: number;
  websiteManagementCareDetails: IWebsiteManagementCareDetails | null;
  websiteManagementMilestoneList: IWebsiteManagementMileStones[];
  websiteManagementMilestoneListCount: number;
  pluginList: IPluginList[];
  securityAddPluginList: ISecurityAddPluginList[];
  virusMalwareList: IVirusMalwareArr[];
}

@State<IWebsiteManagementStateModel>({
  name: "WebsiteManagementState",
  defaults: {
    websiteList: [],
    websiteListCount: 0,
    websiteManagementCareDetails: null,
    websiteManagementMilestoneList: [],
    websiteManagementMilestoneListCount: 0,
    pluginList: [],
    securityAddPluginList: [],
    virusMalwareList: [],
  },
})
@Injectable()
export class WebsiteManagementState {
  constructor(private _http: HttpService, private _toastr: ToastrService) {}

  @Selector()
  static websiteList(state: IWebsiteManagementStateModel) {
    return state.websiteList;
  }

  @Selector()
  static websiteListCount(state: IWebsiteManagementStateModel) {
    return state.websiteListCount;
  }

  @Selector()
  static websiteManagementCareDetails(state: IWebsiteManagementStateModel) {
    return state.websiteManagementCareDetails;
  }

  @Selector()
  static websiteManagementMilestoneList(state: IWebsiteManagementStateModel) {
    return state.websiteManagementMilestoneList;
  }

  @Selector()
  static websiteManagementMilestoneListCount(
    state: IWebsiteManagementStateModel
  ) {
    return state.websiteManagementMilestoneListCount;
  }

  @Selector()
  static pluginList(state: IWebsiteManagementStateModel) {
    return state.pluginList;
  }

  @Selector()
  static securityAddPluginList(state: IWebsiteManagementStateModel) {
    return state.securityAddPluginList;
  }

  @Selector()
  static virusMalwareList(state: IWebsiteManagementStateModel) {
    return state.virusMalwareList;
  }

  @Action(WebsiteManagementList)
  WebsiteManagementList(
    ctx: StateContext<IWebsiteManagementStateModel>,
    { param }: WebsiteManagementList
  ) {
    return this._http.post("website/list", param).pipe(
      tap((apiResult) => {
        const resultData = apiResult.response.dataset;
        ctx.patchState({
          websiteList: resultData.websites,
          websiteListCount: resultData.total_rows,
        });
      })
    );
  }

  @Action(WebsiteManagementDelete)
  WebsiteManagementDelete(
    ctx: StateContext<IWebsiteManagementStateModel>,
    { param }: WebsiteManagementDelete
  ) {
    return this._http.post("website/delete", param).pipe(
      tap((apiResult) => {
        ctx.setState(
          patch({
            websiteList: removeItem<IWebsiteManagementList>(
              (item) => item.website_id === param.id
            ),
          })
        );
        this._toastr.success(apiResult.response.status.msg, "success", {
          closeButton: true,
          timeOut: 3000,
        });
      })
    );
  }

  @Action(WebsiteManagementView)
  WebsiteManagementView(
    ctx: StateContext<IWebsiteManagementStateModel>,
    { param }: WebsiteManagementView
  ) {
    return this._http.post("website/view-milestones", param).pipe(
      tap((apiResult) => {
        const resultData = apiResult.response.dataset;
        ctx.patchState({
          websiteManagementCareDetails: resultData,
          websiteManagementMilestoneList: resultData.milestones,
          websiteManagementMilestoneListCount: resultData.total_rows,
          virusMalwareList: resultData.virus_and_malware_scan_report_list,
        });
      })
    );
  }

  @Action(EditCms)
  EditCms(ctx: StateContext<IWebsiteManagementStateModel>, { param }: EditCms) {
    return this._http.post("website/update-cms", param).pipe(
      tap((apiResult) => {
        this._toastr.success(apiResult.response.status.msg, "success", {
          closeButton: true,
          timeOut: 3000,
        });
      })
    );
  }

  @Action(AddEditSecurityPatch)
  AddEditSecurityPatch(
    ctx: StateContext<IWebsiteManagementStateModel>,
    { param }: AddEditSecurityPatch
  ) {
    return this._http.post("website/add-edit-security-patch", param).pipe(
      tap((apiResult) => {
        this._toastr.success(apiResult.response.status.msg, "success", {
          closeButton: true,
          timeOut: 3000,
        });
      })
    );
  }

  @Action(SecurityPatchDelete)
  SecurityPatchDelete(
    ctx: StateContext<IWebsiteManagementStateModel>,
    { param }: SecurityPatchDelete
  ) {
    return this._http.post("website/delete-security-patch", param).pipe(
      tap((apiResult) => {
        this._toastr.success(apiResult.response.status.msg, "success", {
          closeButton: true,
          timeOut: 3000,
        });
      })
    );
  }

  @Action(FetchPluginList)
  FetchPluginList(
    ctx: StateContext<IWebsiteManagementStateModel>,
    { param }: FetchPluginList
  ) {
    return this._http.post("website/plugin-list", param).pipe(
      tap((apiResult) => {
        const resultData = apiResult.response.dataset;
        ctx.patchState({
          pluginList: resultData.plugins,
        });
      })
    );
  }

  @Action(UpdateMilestoneStatus)
  UpdateMilestoneStatus(
    ctx: StateContext<IWebsiteManagementStateModel>,
    { param }: UpdateMilestoneStatus
  ) {
    return this._http.post("website/milestone-status-update", param).pipe(
      tap((apiResult) => {
        this._toastr.success(apiResult.response.status.msg, "success", {
          closeButton: true,
          timeOut: 3000,
        });
      })
    );
  }

  @Action(SecurityAddPluginList)
  SecurityAddPluginList(
    ctx: StateContext<IWebsiteManagementStateModel>,
    { param }: SecurityAddPluginList
  ) {
    return this._http.post("website/latest-versions", param).pipe(
      tap((apiResult) => {
        const resultData = apiResult.response.dataset;
        ctx.patchState({
          securityAddPluginList: resultData.plugin_versions,
        });
      })
    );
  }

  @Action(UpdateVirusMalware)
  UpdateVirusMalware(
    ctx: StateContext<IWebsiteManagementStateModel>,
    { param }: UpdateVirusMalware
  ) {
    return this._http.post("website/update-virus", param).pipe(
      mergeMap((apiResult) => {
        this._toastr.success(apiResult.response.status.msg, "success", {
          closeButton: true,
          timeOut: 3000,
        });
        return this._http
          .post("website/view-milestones", {
            type: "web-care-plan",
            website_id: param.website_id,
          })
          .pipe(
            tap((apiResultdata) => {
              const resultData = apiResultdata.response.dataset;
              ctx.patchState({
                virusMalwareList: resultData.virus_and_malware_scan_report_list,
              });
            })
          );
      })
    );
  }
}
