export class WebsiteManagementList {
  static readonly type = '[WebsiteManagementList] Post';
  constructor(public param: { client_id: number, list_for?:number }) {}
}

export class WebsiteManagementDelete {
  static readonly type = '[WebsiteManagementDelete] post';
  constructor(public param: { id: number }) {}
}

export class WebsiteManagementView {
  static readonly type = '[WebsiteManagementView] Post';
  constructor(public param: IWebsiteManagementViewMilestoneParam) {}
}

export class EditCms {
  static readonly type = '[EditCms] Post';
  constructor(public param: IEditCmsParam) {}
}

export class AddEditSecurityPatch {
  static readonly type = '[AddEditSecurityPatch] Post';
  constructor(public param: IAddEditSecurityPatchParam) {}
}

export class SecurityPatchDelete {
  static readonly type = '[SecurityPatchDelete] post';
  constructor(public param: { patch_id: number }) {}
}

export class FetchPluginList {
  static readonly type = '[FetchPluginList] Post';
  constructor(public param: { tech_id: number, website_id: number }) {}
}

export class UpdateMilestoneStatus {
  static readonly type = '[UpdateMilestoneStatus] Post';
  constructor(public param: IUpdateMileStoneStatusParam) {}
}

export class SecurityAddPluginList {
  static readonly type = '[SecurityAddPluginList] Post';
  constructor(public param: ISecurityAddPluginParam) {}
}

export class UpdateVirusMalware {
  static readonly type = '[UpdateVirusMalware] Post';
  constructor(public param: IVirusUpdateParam) {}
}

